import React, { useEffect } from 'react'
import PageContainer from '../../utils/PageContainer'
import styles from './ChildrenList.module.css'
import { fetchChildrenList } from '../../../model/pages/ChildrenList/actions/list'
import { connect } from 'react-redux'
import ChildrenCard from './Card'
import { setSelectedItem } from '../../../model/pages/ChildrenList/actions/selectedItem'
import { fetchGOUInfo } from '../../../model/common/GOUinfo/actions'
import Header from '../../utils/Header'
import Navbar from '../../utils/Navbar'
import Spinner from '../../utils/Spinner'
import Container from '../../utils/Container'
import { Row } from 'shards-react'

const mapStateToProps = ({ childrenList, auth, GOUInfo }) => ({
  list: childrenList.list,
  loader: childrenList.loader,
  selectedItem: childrenList.selectedItem,
  auth: auth.data,
  GOUInfo,
})

const mapDispatchToProps = {
  requestList: fetchChildrenList,
  setSelectedItem: setSelectedItem,
  fetchGOUInfo: fetchGOUInfo,
}

const ChildrenListContainer = ({ requestList, loader, list, auth, setSelectedItem, selectedItem, fetchGOUInfo, GOUInfo }) => {
  useEffect(() => {
    fetchGOUInfo()
  }, [fetchGOUInfo])

  useEffect(() => {
    if (!list.length) {
      requestList()
    }
  }, [requestList])

  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.left}>
          <Header logo={GOUInfo.logo} GOUName={GOUInfo.name} />
          <Navbar />
          <Container>
            <Row>
              {loader ? (
                <Spinner />
              ) : (
                list.map(item => (
                  <ChildrenCard
                    studentData={item}
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                    authData={auth}
                  />
                ))
              )}
            </Row>
          </Container>
        </div>
      </div>
    </PageContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenListContainer)
