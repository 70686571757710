import {GOU_INFO_REQUEST, SUCCESS} from "../../../../constants/action-types";

const initialState = {
    name: '',
    logo: ''
};

export const GOUInfoReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${GOU_INFO_REQUEST}${SUCCESS}`:
            return action.payload;


        default:
            return state;
    }
};