import {
    APPLICANT_SCHEDULE_LIST_REQUEST, START,
    SUCCESS
} from "../../../../../constants/action-types";

const initialState = [];

export const ApplicantScheduleListReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${APPLICANT_SCHEDULE_LIST_REQUEST}${START}`:
            return initialState;

        case `${APPLICANT_SCHEDULE_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};