import {
    APPLICANT_PSYCHO_REQUEST,
    ERROR,
    START,
    SUCCESS
} from "../../../../constants/action-types";
import {BASE_PARAMS} from "../../../../constants/api";
import {api} from "../../../../App";


const applicantPsychoRequestStart = () => ({
    type: `${APPLICANT_PSYCHO_REQUEST}${START}`
});

const applicantPsychoRequestSuccess = (data) => ({
    type: `${APPLICANT_PSYCHO_REQUEST}${SUCCESS}`,
    payload: data
});

const applicantPsychoRequestError = (data) => ({
    type: `${APPLICANT_PSYCHO_REQUEST}${ERROR}`,
    payload: data
});



export const fetchApplicantPsycho = (applicant) => (dispatch, getState) => {

    const authState = getState().auth.data;

    dispatch(applicantPsychoRequestStart());

    const params = {
        ...BASE_PARAMS,
        action: 'applicant.getpsy',
        base: authState.base,
        login: authState.login,
        pass: authState.pass,
        applicant
    };

    api.get('/', {params})
        .then(res => {
            dispatch(applicantPsychoRequestSuccess(res.data.Answer.Data))
        })
        .catch(err => {
            dispatch(applicantPsychoRequestError(err.message))
        })
};

export const saveApplicantPsycho = (applicant, callback, inputs) => (dispatch, getState) => {
    const authState = getState().auth.data;

    const _inputsData = {};

    inputs.forEach(item => {
        _inputsData[`test${item.grade}`] = item.psycho_grade;
    });

    const params = {
        ...BASE_PARAMS,
        action: 'applicant.setpsy',
        base: authState.base,
        login: authState.login,
        pass: authState.pass,
        applicant,
        ..._inputsData
    };

    api.get('/', {params})
        .then(() => {
            callback();
            dispatch(fetchApplicantPsycho(applicant));
        })
        .catch(err => {
            console.log(err)
        })
};