import {APPLICANT_PSYCHO_REQUEST, START, SUCCESS} from "../../../../constants/action-types";

const initialState = [];


export const psychoReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${APPLICANT_PSYCHO_REQUEST}${START}`:
            return [];

        case `${APPLICANT_PSYCHO_REQUEST}${SUCCESS}`:
            return action.payload;


        default:
            return state;
    }
};