import {combineReducers} from "redux";
import {childrenListReducer} from "./list/childrenListReducer";
import {childrenListLoaderReducer} from "./loader/childrenListLoaderReducer";
import {selectedItemReducer} from "./selecteditem/selectedItemReducer";


export const chidlrenReducer = combineReducers({
    list: childrenListReducer,
    loader: childrenListLoaderReducer,
    selectedItem: selectedItemReducer
})