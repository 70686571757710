import {CHILDREN_LIST_REQUEST, ERROR, START, SUCCESS} from "../../../../../constants/action-types";

const initialState = false;

export const childrenListLoaderReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${CHILDREN_LIST_REQUEST}${START}`:
            return true;

        case `${CHILDREN_LIST_REQUEST}${SUCCESS}`:
            return false;

        case `${CHILDREN_LIST_REQUEST}${ERROR}`:
            return false;

        default:
            return state;
    }
};