import React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'shards-ui/dist/css/shards.min.css'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import AuthContainer from './components/pages/Auth'
import ChildrenListContainer from './components/pages/ChildrenList'
import { configureStore } from './model/store'
import EnrollmentContainer from './components/pages/Enrollment'
import PrivateRoute from './components/utils/PrivateRoute'
import { configureApi } from './model/api'
import ApplicantScheduleContainer from './components/pages/ApplicantSchedule'

export const store = configureStore()
export const api = configureApi(store)

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path='/auth' component={AuthContainer} />
          <PrivateRoute exact path='/' component={ApplicantScheduleContainer} />
          <PrivateRoute exact path='/children_list' component={ChildrenListContainer} />
          <PrivateRoute exact path='/enrollment' component={EnrollmentContainer} />
        </Switch>
      </BrowserRouter>
      <NotificationContainer />
    </Provider>
  )
}

export default App
