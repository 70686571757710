import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { fetchGOUInfo } from '../../../model/common/GOUinfo/actions'
import { fetchApplicantScheduleList, setSelectedItem } from '../../../model/pages/ApplicantSchedule/actions'
import PageContainer from '../../utils/PageContainer'
import Header from '../../utils/Header'
import Navbar from '../../utils/Navbar'
import Timer from '../../utils/Timer'
import Spinner from '../../utils/Spinner'
import Container from '../../utils/Container'
import Card from './Card'
import styles from './AS.module.css'
import { FormInput, Row } from 'shards-react'

const mapStateToProps = ({ applicantSchedule, auth, GOUInfo }) => ({
  list: applicantSchedule.list,
  loader: applicantSchedule.loader,
  selectedItem: applicantSchedule.selectedItem,
  auth: auth.data,
  GOUInfo,
})

const mapDispatchToProps = {
  requestList: fetchApplicantScheduleList,
  setSelectedItem: setSelectedItem,
  fetchGOUInfo: fetchGOUInfo,
}

const ApplicantScheduleContainer = ({
  fetchGOUInfo,
  GOUInfo,
  list,
  requestList,
  loader,
  auth,
  selectedItem,
  setSelectedItem,
}) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))

  useEffect(() => {
    fetchGOUInfo()
  }, [fetchGOUInfo])

  useEffect(() => {
    requestList(date)
  }, [date])

  const onChane = e => {
    setDate(e.target.value)
  }

  return (
    <PageContainer>
      <Header logo={GOUInfo.logo} GOUName={GOUInfo.name} />
      <Navbar />
      <Container>
        <div className={styles.controls}>
          <div className={styles.date}>
            <p>Кандидаты на собеседование на дату</p>
            <FormInput type={'date'} name={'date'} value={date} onChange={onChane} />
          </div>
          <Timer />
        </div>
        <Row>
          {loader ? (
            <Spinner />
          ) : (
            list.map(item => (
              <Card
                studentData={item}
                key={item.schedule_id}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                authData={auth}
              />
            ))
          )}
        </Row>
      </Container>
    </PageContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantScheduleContainer)
