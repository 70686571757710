import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FormSelect } from "shards-react";
import { FormInput } from "shards-react";
import { Button } from "shards-react";

import styles from './AuthForm.module.css';

import no_user from '../../../../images/nouser.png';

const AuthForm = ({ onSubmit, orgList, errorMessage }) => {

    const [formData, setFormData] = useState({
        login: '',
        pass: '',
        base: ''
    });

    const [error, setError] = useState(errorMessage);

    useEffect(() => {
        if(errorMessage) {
            setError(errorMessage);
        }
    }, [errorMessage]);

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    };

    const checkOnSubmit = () => {
        if(!formData.login || !formData.pass || !formData.base) {
            setError('Необходимо заполнить все поля')
        } else {
            setError('');
            onSubmit(formData);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.user}>
                <img src={no_user} alt=""/>
            </div>
            <FormSelect name="base"
                        onChange={onChange}
                        value={formData.base}
                        className={'mb-2'}
                        disabled={!orgList.length}
            >
                <option value="">Выберите организацию</option>
                {orgList.map(item =>
                    <option key={item.Name} value={item.Name}>{ item.Title }</option>
                )}
            </FormSelect>

            <FormInput
                name={'login'}
                value={formData.login}
                onChange={onChange}
                placeholder={'Логин'}
                className={'mb-2'}
            />
            <FormInput
                name={'pass'}
                value={formData.pass}
                onChange={onChange}
                placeholder={'Пароль'}
                className={'mb-2'}
                type={'password'}
            />

            <Button onClick={checkOnSubmit} block>Войти</Button>


            <div className={styles.pdfload}>
                <a href="https://join.edumil.ru/manual.pdf" target={'_blank'}>Скачать инструкцию PDF</a>
            </div>



            {error &&
            <div className={styles.error}>
                <p>{ error }</p>
                <Button onClick={() => setError('')}>ОK</Button>
            </div>
            }
        </div>
    );
};

AuthForm.propTypes = {
    orgList: PropTypes.array,
};

export default AuthForm;