import {combineReducers} from "redux";
import {ApplicantScheduleListReducer} from "./list/ApplicantScheduleListReducer";
import {ApplicantScheduleLoaderReducer} from "./loader/ApplicantScheduleLoaderReducer";
import {ApplicantScheduleSelectedItemReducer} from "./selectedItem/ApplicantScheduleSelectedItemReducer";


export const ApplicantScheduleReducer = combineReducers({
    list: ApplicantScheduleListReducer,
    loader: ApplicantScheduleLoaderReducer,
    selectedItem: ApplicantScheduleSelectedItemReducer
});