import {combineReducers} from "redux";
import { AuthReducer } from "../pages/Auth/reducers/AuthReducer";
import {chidlrenReducer} from "../pages/ChildrenList/reducers/childrenList";
import {GOUInfoReducer} from "../common/GOUinfo/reducers/GOUInfoReducer";
import {ApplicantScheduleReducer} from "../pages/ApplicantSchedule/reducers/ApplicantScheduleReducer";
import {psychoReducer} from "../common/psycho/reducers/psychoReducer";

export const rootReducer = combineReducers({
   auth: AuthReducer,
   childrenList: chidlrenReducer,
   applicantSchedule: ApplicantScheduleReducer,
   GOUInfo: GOUInfoReducer,
   psycho: psychoReducer
});

export type T_rootReducer = ReturnType<typeof rootReducer>