import React from 'react';
import PropTypes from 'prop-types';
import styles from "../Task.module.css";

const TextUserAnswer = ({ answerList, fieldName }) => {
    return (
        <div className={styles.question_items}>
            {answerList.map((item, index) =>
                <div className={styles.answer_item}>
                    {item[fieldName]}
                </div>
            )}
        </div>
    );
};

TextUserAnswer.propTypes = {
    answerList: PropTypes.array,
    fieldName: PropTypes.string,
};

export default TextUserAnswer;