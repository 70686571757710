import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import styles from '../../ChildrenList/ChildrenList.module.css'

import { usePhoto } from '../../../../helpers/usePhoto/usePhoto'
import SelectedStudentButtonPanel from '../../../utils/SelectedStudentButtonPanel'
import { api } from '../../../../App'
import { createNotification } from '../../../utils/Notification'
import { Col } from 'shards-react'

const Card = ({ studentData, setSelectedItem, selectedItem, authData }) => {
  const {
    Child_LastName,
    Child_FirstName,
    Child_MidName,
    Child_Birth,
    Child_Class,
    NotificationPhone,
    Email,
    applicant_id,
    access_code,
    members,
    start_time,
    stop_time,
    photo_exists,
    schedule_id,
  } = studentData

  const fetchPhotoParams = Number(photo_exists)
    ? {
        action: 'applicant.getphoto',
        id: applicant_id,
        base: authData.base,
        login: authData.login,
        pass: authData.pass,
      }
    : null //Проверяем есть ли фото пользователя

  const isItemSelected = selectedItem ? selectedItem.schedule_id === studentData.schedule_id : false

  const photo = usePhoto(fetchPhotoParams)
  const [accessCode, setAccessCode] = useState(access_code)

  const history = useHistory()

  const selectItem = () => {
    if (isItemSelected) {
      setSelectedItem(null)
    } else {
      setSelectedItem(studentData)
    }
  }

  const sendAccessCode = () => {
    const params = {
      action: 'applicant.sendcodebyemail',
      base: authData.base,
      login: authData.login,
      pass: authData.pass,
      type: 'teacher',
      format: 'json',
      schedule: schedule_id,
    }

    api
      .get('/', { params })
      .then(res => {
        const accesCode = res.data.Answer.Data_.Code

        setAccessCode(accesCode)
        setSelectedItem({
          ...selectedItem,
          access_code: accesCode,
        })

        console.log(accesCode)
        history.push('/enrollment')

        createNotification('success', 'Код доступа успешно отправлен')
      })
      .catch(err => {
        console.log(err)
        createNotification('warning', 'Ошибка: код доступа не отправлен')
      })
  }

  const sendInvite = () => {
    const params = {
      action: 'applicant.invitationbyemail',
      base: authData.base,
      type: 'teacher',
      login: authData.login,
      pass: authData.pass,
      schedule: selectedItem.schedule_id,
    }

    api
      .get('/', { params })
      .then(res => {
        createNotification('success', 'Приглашение успешно отправлено')
        console.log(res)
      })
      .catch(err => {
        createNotification('warning', 'Ошибка: приглашение не отправлено')
        console.log(err)
      })
  }

  return (
    <div className={classNames(styles.listCard)}>
      <div
        className={classNames(styles.card, {
          [styles.listCardSelected]: isItemSelected,
        })}
        onClick={selectItem}
      >
        <div className={styles.cardHeader}>
          <div className={styles.studentInfo}>
            <h4>
              {Child_LastName} <br />
              {Child_FirstName} <br />
              {Child_MidName}
            </h4>
            {Child_Birth && <div className={styles.studentYear}>Дата рождения: {Child_Birth.split(' ')[0]}</div>}
            <div className={styles.studentClassroom}>Поступает в {Child_Class} класс</div>
            <div>Код подключения: {accessCode}</div>
            {NotificationPhone && <div>Телефон: {NotificationPhone}</div>}
            {Email && <div>Email: {Email}</div>}
            <div className={styles.sessionTime}>
              {start_time} - {stop_time}
            </div>
          </div>
          <div className={styles.studentImg}>
            <img src={photo} alt='' />
          </div>
        </div>
        <div className={styles.cardContent}>
          <p className={classNames(styles.underline, styles.cardContent_title)}>Комиссия:</p>
          <div>{members && members.split(',').map(member => <p className={'mb-1'}>{member}</p>)}</div>
        </div>
      </div>
      {isItemSelected && <SelectedStudentButtonPanel sendAccessCode={sendAccessCode} sendInvite={sendInvite} />}
    </div>
  )
}

Card.propTypes = {
  studentData: PropTypes.object,
}

export default Card
