import React, {useEffect, useRef} from "react";
import styles from './Task.module.css';
import { Scrollbars } from 'react-custom-scrollbars';

import classNames from 'classnames';
import Spinner from "../../../utils/Spinner";
import TextUserAnswer from "./TextUserAnswer";


const Task = ({data, question, count, loader, ticketNumber, userAnswer, requestApplicantAnswer, getAnswer}) => {

    const questionContainer = useRef(null);

    useEffect(() => {
        getAnswer(data.id_question)
    }, [count]);

    useEffect(() => {
        if (question) {
            questionContainer.current.innerHTML = question.question_text;
        }
    }, [question]);

    useEffect(() => {
        requestApplicantAnswer(data.id_question);
    }, [count]);


    return (
        <div className={styles.container}>
            <p className={styles.id_question}>{ticketNumber && `Билет №${ticketNumber}, `}#{data.id_question}</p>
            <div className={styles.title_block}>
                <div>{data.Lesson}</div>
                <h2>Задание {data.q_order}</h2>
            </div>
            <div className={styles.task_container}>
                <div className={styles.task_details}>
                    <Scrollbars>
                        {loader &&
                        <Spinner color={'#FFFFFF'}/>
                        }
                        <div ref={questionContainer} className={classNames({
                            'd-none': loader,
                        }, styles.task_item)}/>


                        {question && question.question_type === 'select' &&
                        <TextUserAnswer fieldName={'answer_text'} answerList={question.answers}/>
                        }

                        {question && question.question_type === 'class' &&
                        <TextUserAnswer fieldName={'text'} answerList={question.zones}/>
                        }

                        {question && question.question_type === 'wordorder' &&
                        question.zones.map(item =>
                            <TextUserAnswer fieldName={'answer_text'} answerList={item.answers}/>
                        )}

                        {question && question.question_type === "tasklist" &&
                        <TextUserAnswer fieldName={'text'} answerList={question.zones}/>
                        }
                        {question && question.question_type === "questionlist" &&
                        <TextUserAnswer fieldName={'text'} answerList={question.zones}/>
                        }

                        {question && question.question_type === "check" &&
                        <TextUserAnswer fieldName={'text'} answerList={question.zones}/>
                        }

                        {userAnswer &&
                        <div className={styles.task_answer_title}>Ответ кандидата: </div>
                        }
                        <div className={styles.answer_row}>
                            {userAnswer && userAnswer.map(item => <>
                                {item.solution &&
                                <div className={styles.answer_item}>
                                    {item.solution}
                                </div>
                                }

                                {item.answer &&
                                <div className={styles.answer_item}>
                                    {item.answer}
                                </div>
                                }
                            </>)}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </div>
    )
};

export default Task;