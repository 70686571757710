import React from 'react';
import PropTypes from 'prop-types';
import styles from "./SP.module.css";

const StatePlug = ({text}) => {
    return (
        <div className={styles.container}>
            <div>
                { text }
            </div>
        </div>
    );
};

StatePlug.propTypes = {
    text: PropTypes.string.isRequired,
};

export default StatePlug;