import {SET_SELECTED_ITEM, ERROR, START, SUCCESS} from "../../../../../constants/action-types";

const initialState = null;

export const selectedItemReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_SELECTED_ITEM:
            return action.payload;


        default:
            return state;
    }
};