import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from '../ChildrenList.module.css'
import moment from 'moment'
import classNames from 'classnames'
import { usePhoto } from '../../../../helpers/usePhoto/usePhoto'

const ChildrenCard = ({ studentData, setSelectedItem, selectedItem, authData }) => {
  const {
    Child_LastName,
    Child_FirstName,
    Child_MidName,
    Child_Birth,
    Child_Class,
    xp_key,
    photo_exists,
    NotificationPhone,
    Email,
  } = studentData

  const fetchPhotoParams = Number(photo_exists)
    ? {
        action: 'applicant.getphoto',
        id: xp_key,
        base: authData.base,
        login: authData.login,
        pass: authData.pass,
      }
    : null //Проверяем есть ли фото пользователя

  const photo = usePhoto(fetchPhotoParams)

  const selectItem = () => {
    if (selectedItem === studentData) {
      setSelectedItem(null)
    } else {
      setSelectedItem(studentData)
    }
  }

  return (
    <div className={classNames(styles.listCard)} onClick={selectItem}>
      <div
        className={classNames(styles.card, {
          [styles.listCardSelected]: selectedItem === studentData,
        })}
      >
        <div className={styles.cardHeader}>
          <div className={styles.studentInfo}>
            <h4>
              {Child_LastName} <br />
              {Child_FirstName} <br />
              {Child_MidName}
            </h4>
            {Child_Birth && <div className={styles.studentYear}>Дата рождения: {Child_Birth.split(' ')[0]}</div>}

            <div className={styles.studentClassroom}>Поступает в {Child_Class} класс</div>
            {NotificationPhone && <div>Телефон: {NotificationPhone}</div>}
            {Email && <div>Email: {Email}</div>}
          </div>
          <div className={styles.studentImg}>
            <img src={photo} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

ChildrenCard.propTypes = {
  studentData: PropTypes.object,
}

export default ChildrenCard
