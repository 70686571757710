import React from 'react';
import PropTypes from 'prop-types';
import mo_logo from "../../../images/mo_logo.png";
import s from './Header.module.css';

const Header = ({ GOUName, logo }) => {
    return (
        <header className={s.container}>
            <div className={s.logo}>
                <div className={s.logoImage} style={{backgroundImage: 'url(' + logo + ')'}}>
                </div>
                <div className={s.logoTitleContainer}>
                    <div className={s.logoTitle}>
                        { GOUName }
                    </div>
                </div>
            </div>
            <div className={s.app_title_container}>
                <div>
                    <img src={mo_logo} alt=""/>
                    <div className={s.mo_title}>
                        МИНИСТЕРСТВО ОБОРОНЫ РОССИЙСКОЙ ФЕДЕРАЦИИ
                    </div>
                    <div className={s.app_title}>
                        ВСТУПИТЕЛЬНЫЕ ИСПЫТАНИЯ - 2021
                    </div>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    GOUName: PropTypes.string,
    logo: PropTypes.string,
};

export default Header;