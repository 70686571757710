import React from 'react';
import PropTypes from 'prop-types';
import styles from './Navbar.module.css';
import {resetAuth} from "../../../model/pages/Auth/actions/auth";
import {connect} from "react-redux";
import {Link} from "react-router-dom";


const mapDispatchToProps = {
    resetAuthData: resetAuth
};

const Navbar = ({ resetAuthData }) => {

    const logOut = () => resetAuthData();

    return (
        <nav className={styles.navbar}>
            <Link to={'/'}>РАСПИСАНИЕ СОБЕСЕДОВАНИЙ</Link>
            <Link to={'/children_list'}>СПИСОК КАНДИДАТОВ</Link>

            <a onClick={logOut}>ВЫХОД</a>
        </nav>
    );
};

Navbar.propTypes = {};

export default connect(
    null,
    mapDispatchToProps
)(Navbar);