import React, {useEffect, useState} from 'react';
import AuthForm from "./Form";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";

import {api} from "../../../App";
import styles from './AuthContainer.module.css';
import {BASE_PARAMS, BASE_URL} from "../../../constants/api";
import {startAuth} from "../../../model/pages/Auth/actions/auth";

import bg from '../../../images/bg.jpg';


const mapStateToProps = ({auth}) => ({
    authData: auth.data,
    error: auth.error
});

const mapDispatchToProps = {
    authRequest: startAuth
};

const AuthContainer = ({authData, error, authRequest}) => {

    const [orgList, setOrgList] = useState([]);

    let history = useHistory();

    useEffect(() => {
        if (authData) history.push('/')
    }, [authData, history]);


    useEffect(() => {
        api.get('/?action=databases&MO_Schools=1', {params: BASE_PARAMS})
            .then(res => {
                setOrgList(res.data.Answer.Data);
            })
    }, []);


    return (
        <div className={styles.container} style={{backgroundImage: 'url(' + bg + ')'}}>
            <h1 className={styles.title}>ВСТУПИТЕЛЬНЫЕ ИСПЫТАНИЯ - 2021</h1>
            <AuthForm orgList={orgList} onSubmit={authRequest} errorMessage={error}/>
            <div className={styles.phone_block}>
                <p>Телефон горячей линии </p>
                <a href="tel:88003331565" className={styles.submenu_tel}> 8-800-333-1565</a>
            </div>
        </div>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthContainer);