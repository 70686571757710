import {CHILDREN_LIST_REQUEST, ERROR, START, SUCCESS} from "../../../../constants/action-types";
import {api} from "../../../../App";
import {authRequestError, authRequestStart, authRequestSuccess} from "../../Auth/actions/auth";

export const childrenListRequestStart = () => ({
    type: `${CHILDREN_LIST_REQUEST}${START}`
});

export const childrenListRequestSuccess = (data) => ({
    type: `${CHILDREN_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const childrenListRequestError = (data) => ({
    type: `${CHILDREN_LIST_REQUEST}${ERROR}`,
    payload: data
});


export const fetchChildrenList = () => {

    return (dispatch, getState) => {
        dispatch(childrenListRequestStart());

        const authState = getState().auth.data;

        const params = {
            action: "applicant.list",
            type: "teacher",
            format: "json",
            base: authState.base,
            login: authState.login,
            pass: authState.pass
        };

        api.get('', {params})
            .then(res => {
                dispatch(childrenListRequestSuccess(res.data.Answer.Data))
            })
            .catch(err => {
                dispatch(childrenListRequestError(err.message))
            })

    }
};