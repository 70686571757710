import {saveAs} from "file-saver";

export const streamRecorder = () => {
    return {
        recordedBlobs: [],
        mediaRecorder: null,
        //methods
        start(srcObject) {
            let options = {mimeType: 'video/webm;codecs=vp9,opus'};
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                console.error(`${options.mimeType} is not supported`);
                options = {mimeType: 'video/webm;codecs=vp8,opus'};
                if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                    console.error(`${options.mimeType} is not supported`);
                    options = {mimeType: 'video/webm'};
                    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                        console.error(`${options.mimeType} is not supported`);
                        options = {mimeType: ''};
                    }
                }
            }

            try {
                this.mediaRecorder = new MediaRecorder(srcObject, options);
            } catch (e) {
                return console.error('Exception while creating MediaRecorder:', e);
            }

            this.mediaRecorder.onstop = (event) => {
                console.log('Recorder stopped: ', event);
                console.log('Recorded Blobs: ', this.recordedBlobs);
            };
            this.mediaRecorder.ondataavailable = (event) => {
                console.log('handleDataAvailable', event);
                if (event.data && event.data.size > 0) {
                    this.recordedBlobs.push(event.data);
                }
            };
            this.mediaRecorder.start();
        },
        stopRecording() {
            if(
                this.mediaRecorder
                &&
                this.mediaRecorder.state !== "inactive"
            ) {
                this.mediaRecorder.stop();
            }
        },
        downloadStream(fileName) {
            if(this.mediaRecorder) {
                const blob = new Blob(this.recordedBlobs, {type: 'video/webm'});
                saveAs(blob, `${fileName}.webm`);
            }
        }
    }
};