import {APPLICANT_SCHEDULE_LIST_REQUEST, ERROR, START, SUCCESS} from "../../../../constants/action-types";
import {api} from "../../../../App";
import {BASE_PARAMS} from "../../../../constants/api";


export const applicantScheduleListRequestStart = () => ({
    type: `${APPLICANT_SCHEDULE_LIST_REQUEST}${START}`
});

export const applicantScheduleListRequestSuccess = (data) => ({
    type: `${APPLICANT_SCHEDULE_LIST_REQUEST}${SUCCESS}`,
    payload: data
});

export const applicantScheduleListRequestError = (data) => ({
    type: `${APPLICANT_SCHEDULE_LIST_REQUEST}${ERROR}`,
    payload: data
});

export const setSelectedItem = (item) => ({
    type: 'SET_APPLICANT_SCHEDULE_SELECTED_ITEM',
    payload: item
});


export const fetchApplicantScheduleList = (date) => {

    return (dispatch, getState) => {
        dispatch(applicantScheduleListRequestStart());

        const authState = getState().auth.data;

        const params = {
            ...BASE_PARAMS,
            action: "applicant.schedulelist",
            base: authState.base,
            login: authState.login,
            pass: authState.pass,
            date
        };

        api.get('', {params})
            .then(res => {
                dispatch(applicantScheduleListRequestSuccess(res.data.Answer.Data))
            })
            .catch(err => {
                dispatch(applicantScheduleListRequestError(err.message))
            })

    }
};