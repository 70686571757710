import {authRequestError, authRequestStart, authRequestSuccess} from "../actions/auth";
import {AUTH_REQUEST, ERROR, RESET_AUTH_DATA, START, SUCCESS} from "../../../../constants/action-types";


/*************** REDUCER *******************/
const initialState = {
   data: (localStorage.getItem('base'))
      ? {
         base: localStorage.getItem('base'),
         MID:  localStorage.getItem('MID'),
         Type:  localStorage.getItem('Type'),
         login:  localStorage.getItem('login'),
         pass:  localStorage.getItem('pass'),
      }
      : undefined,
   error: null
};

export const AuthReducer = (state = initialState, action) => {
   switch (action.type) {
      case RESET_AUTH_DATA:
         return {
            data: undefined,
            error: null
         };

      case `${AUTH_REQUEST}${START}`:
         return {
            ...state,
            error: null
         };

      case `${AUTH_REQUEST}${SUCCESS}`:
         return {
            data: action.payload,
            error: null
         };

      case `${AUTH_REQUEST}${ERROR}`:
         return {
            data: null,
            error: action.payload
         };


      default:
         return state;
   }
};