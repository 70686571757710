import {api} from "../../../../App";
import {GOU_INFO_REQUEST, ERROR, START, SUCCESS} from "../../../../constants/action-types";


export const GOUInfoRequestStart = () => ({
    type: `${GOU_INFO_REQUEST}${START}`
});

export const GOUInfoRequestSuccess = (data) => ({
    type: `${GOU_INFO_REQUEST}${SUCCESS}`,
    payload: data
});

export const GOUInfoRequestError = (data) => ({
    type: `${GOU_INFO_REQUEST}${ERROR}`,
    payload: data
});


export const fetchGOUInfo = () => (dispatch, getState) => {

    const authState = getState().auth.data;

    dispatch(GOUInfoRequestStart());

    let fetchGOUName = api.get('/', {
        params: {
            format: 'json',
            action: "GOU_name",
            base: authState.base
        }
    });

    let fetchGOULogo = api.get('/', {
        params: {
            action: "kiosklogo",
            base: authState.base
        },
        responseType: 'arraybuffer'
    }).then(response => Buffer.from(response.data, 'binary').toString('base64'));

    Promise.all([fetchGOULogo, fetchGOUName])
        .then(values => {
            const result = {
                logo: 'data:image/jpeg;base64,' + values[0],
                name: values[1].data.Answer.Data[0].value,
            };
            dispatch(GOUInfoRequestSuccess(result));
        })
        .catch(err => {
            dispatch(GOUInfoRequestError(err.message));
        })
};