import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import styles from "./LessonTimer.module.css";
import * as moment from "moment";
import 'moment/locale/ru';

const LessonTimer = ({}) => {

    const startTime = useMemo(() => moment(), []);

    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(
                moment.utc(moment().diff(moment(startTime,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm")
            )
        }, 2000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.timer}>
                { currentTime }
            </div>
            <div className={styles.calendar}>
                { moment().format('LLLL') }
            </div>
        </div>
    );
};

LessonTimer.propTypes = {};

export default LessonTimer;