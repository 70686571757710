import {CHILDREN_LIST_REQUEST, ERROR, RESET_AUTH_DATA, SUCCESS} from "../../../../../constants/action-types";

const initialState = [];

export const childrenListReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${CHILDREN_LIST_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};