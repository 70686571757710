export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const ERROR = "_ERROR";
export const RESET = "_RESET";

export const AUTH_REQUEST = "AUTH_REQUEST";
export const RESET_AUTH_DATA = "RESET_AUTH_DATA";

export const CHILDREN_LIST_REQUEST = "CHILDREN_LIST_REQUEST";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";

export const GOU_INFO_REQUEST = "GOU_INFO_REQUEST";

export const APPLICANT_SCHEDULE_LIST_REQUEST = "APPLICANT_SCHEDULE_LIST_REQUEST";
export const SET_APPLICANT_SCHEDULE_SELECTED_ITEM = "SET_APPLICANT_SCHEDULE_SELECTED_ITEM";


export const APPLICANT_PSYCHO_REQUEST = "APPLICANT_PSYCHO_REQUEST";
export const APPLICANT_PSYCHO_SAVE_REQUEST = "APPLICANT_PSYCHO_REQUEST";