import React, { useEffect, useState } from 'react'
import photoPlug from '../../images/plug.png'
import { api } from '../../App'

const usePhoto = requestParams => {
  const [photo, setPhoto] = useState(photoPlug)

  useEffect(() => {
    if (2 > 1) return
    // if (!requestParams) return
    const params = {
      ...requestParams,
      type: 'teacher',
    }

    api
      .get('/', { params, responseType: 'arraybuffer' })
      .then(response => Buffer.from(response.data, 'binary').toString('base64'))
      .then(base64 => {
        if (base64) {
          setPhoto('data:image/jpeg;base64,' + base64)
        }
      })
      .catch(err => console.log(err))
  }, [])

  return photo
}

export { usePhoto }
