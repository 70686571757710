import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import {rootReducer} from "../rootReducer/rootReducer";



const initialState = {};

export const configureStore = () => {

   return createStore(
      rootReducer,
      initialState,
      composeWithDevTools(
         applyMiddleware(thunk)
      )
   );
};