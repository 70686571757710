import {SET_APPLICANT_SCHEDULE_SELECTED_ITEM} from "../../../../../constants/action-types";

const initialState = null;

export const ApplicantScheduleSelectedItemReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_APPLICANT_SCHEDULE_SELECTED_ITEM:
            return action.payload;

        default:
            return state;
    }
};


// const initialState = {
//     schedule_id: "26",
//     applicant_id: "3610",
//     slot_id: "6",
//     comitet_id: "10",
//     Child_Class: "5",
//     Child_birth: "06.11.2008 0:00:00",
//     Child_LastName: "АКАТОВ",
//     Child_FirstName: "Макар",
//     Child_MidName: "Андреевич",
//     photo_exists: "0",
//     slot_name: "13",
//     start_time: "13:00",
//     stop_time: "14:00",
//     comitet_name: "Комиссия 10",
//     members: "Зубкова Н.В.,Криштопа А.Н.,Лонин С.О.",
//     access_code: "8941",
//     NotificationPhone: "+79531669664",
//     connect_code: "2678",
//     ticket_number: "50",
// };