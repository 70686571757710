import React from 'react';
import PropTypes from 'prop-types';


import styles from './SSBP.module.css';
import {Button} from "shards-react";

const SelectedStudentButtonPanel = ({sendAccessCode, sendInvite}) => {


    return (
        <div className={styles.buttonPanel}>
            <Button onClick={sendInvite} theme="info">отправить приглашение</Button>
            <Button onClick={sendAccessCode}>начать собеседование</Button>
        </div>
    );
};

SelectedStudentButtonPanel.propTypes = {
    sendAccessCode: PropTypes.func,
    sendInvite: PropTypes.func,
};

export default SelectedStudentButtonPanel;