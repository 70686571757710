import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../utils/Modal";

import styles from './PsyModal.module.css';
import Button from "../../../utils/Button";

const ScoreModal = ({data, setOpen, saveData}) => {

    const [state, setState] = useState(data);

    const closeModal = () => {
        setOpen(false);
    };

    const onChange = (index, e) => {
        const updatedState = [...state];
        updatedState[index].psycho_grade = e.target.value;
        setState(updatedState);
    };

    return (
        <Modal closeModal={closeModal}>
            <div className={styles.psy_container}>
                {state.map((item, index) =>
                    <div className={styles.row} key={item.grade}>
                        <div className={styles.label}>
                            {item.test_name}:
                        </div>
                        <div className={styles.input_container}>
                            <input type={'number'}
                                   value={item.psycho_grade}
                                   onChange={(e) => onChange(index, e)}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.controls}>
                <Button label={'Сохранить'} onClick={() => saveData(closeModal, state)}/>
            </div>
        </Modal>
    );
};

ScoreModal.propTypes = {
    data: PropTypes.array.isRequired,
    saveData: PropTypes.func.isRequired,
};

export default ScoreModal;