import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from "../EnrollmentContainer.module.css";
import {api} from "../../../../App";
import plug from "../../../../images/plug.png";

const ApplicantInfo = ({ selectedItem, authData }) => {

    const userPhotoContainer = useRef(null);
    const [userPhoto, setUserPhoto] = useState(null);

    useEffect(() => {
        if (!selectedItem) return;
        const params = {
            action: "applicant.getphoto",
            id: selectedItem.applicant_id,
            type: 'teacher',
            base: authData.base,
            login: authData.login,
            pass: authData.pass
        };

        api.get('/', {params, responseType: 'arraybuffer'})
            .then(response => Buffer.from(response.data, 'binary').toString('base64'))
            .then(base64 => {
                if(base64 == '') {
                    userPhotoContainer.current.setAttribute('src', `${plug}`);
                }else{
                    setUserPhoto('data:image/jpeg;base64,' + base64)
                }
            })
            .catch(err => console.log(err))
    }, []);

    return (
      <div className={styles.student}>
        <div className={styles.student_wrapper}>
          <div className={styles.student_info}>
            <div className={styles.student_descr}>
              <div className={styles.student_fio}>
                {selectedItem.Child_LastName} <br />
                {selectedItem.Child_FirstName} <br />
                {selectedItem.Child_MidName}
              </div>
              <div>Код подключения: {selectedItem.access_code}</div>
              <div>Дата рождения: {selectedItem.Child_birth && selectedItem.Child_birth.split(' ')[0]}</div>
              <div>Поступает в {selectedItem.Child_Class} класс</div>
              <div>Номер телефона: {selectedItem.NotificationPhone}</div>
              <div>Email: {selectedItem.Email}</div>
            </div>
            <div className={styles.student_photo}>
              <img src={userPhoto} alt='' ref={userPhotoContainer} />
            </div>
          </div>
        </div>
      </div>
    )
};

ApplicantInfo.propTypes = {
    selectedItem: PropTypes.object.isRequired,
    authData: PropTypes.object.isRequired,
};

export default ApplicantInfo;
