import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './Timer.module.css';
import moment from "moment";

const Timer = ({}) => {
    const [time, setTime] = useState('');

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTime(moment().format('HH:mm'));
        }, 1000);

        return () => clearInterval(timerInterval)
    }, []);

    return (
        <div className={styles.timer}>
            { time }
        </div>
    );
};

Timer.propTypes = {};

export default Timer;